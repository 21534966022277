import { Component } from '@angular/core';
import { SupportComponent } from '@shared/ui-elements/support/support.component';

@Component({
    selector: 'app-footer',
    template: '<app-support />',
    standalone: true,
    imports: [SupportComponent],
})
export class FooterComponent {}
